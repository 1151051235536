import {serverSideTranslations} from "next-i18next/serverSideTranslations";
import AuthPage from "@app/views/authPage";
import Head from "next/head";
import React from "react";
import {wrapper} from "@app/store";
import {hasData} from "../../libs/remote";

const Index = () => {

    return (
        <>
            <Head>
                <title>Главная страница</title>
            </Head>
            <AuthPage/>
        </>
    )
}

export const getServerSideProps = wrapper.getServerSideProps(store => async ({ locale}) => {
    const user = await store.getState().core.user;

    if (hasData(user) && user.data.is_user_verified) {
        return {
            redirect: {
                destination: '/myGoods',
                permanent: false,
            }
        }
    }

    return {
        props: {
            ...(await serverSideTranslations(locale, ["common"])),
        },
    };
});

export default Index;
