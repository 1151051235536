import { Tabs } from "antd";
import styled, { css } from "styled-components";

export const TabsGroup = styled(Tabs)`
  ${({ theme: { breakpoints } }) => css`
    display: flex;
    gap: 48px;
    justify-content: center;
    align-items: center;
    margin-top: 200px;

    .ant-tabs-nav-list {
      gap: 2.5rem;
    }

    .ant-tabs-nav {
      margin: 0;

      &::before {
        border-bottom: none;
      }
    }

    .ant-tabs-nav .ant-tabs-ink-bar {
      height: 1px;
    }

    .ant-tabs-ink-bar {
      background: black;
    }

    .ant-tabs-tab {
      padding: 0 0 4px 0;
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 400;
      line-height: 16.35px;
      letter-spacing: 1.2px;

      &:hover {
        color: black;
      }
    }

    .ant-tabs-tab-btn:active {
      color: black;
    }

    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: black;
      text-shadow: none;
    }

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      margin-top: 120px;
    }
  `}
`;
