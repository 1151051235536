import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import * as S from "./styled";
import ReCAPTCHA from "react-google-recaptcha";
import { useEffect, useRef, useState } from "react";
import { openErrorNotification } from "@app/components/ui/notification";
import AuthModal from "../authModal";
import BannedModal from "../bannedModal";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { useAppDispatch, useAppSelector } from "@app/store";
import { selectConfirmStatus, selectSignIn } from "@app/store/core/selectors";
import { hasData, RemoteDataStatus } from "libs/remote";
import { setConfirmStatus, signIn } from "@app/store/core/slice";
import { AuthHeader } from "../authHeader";

const phoneRegExp = /^((8|\+7 9)[\- ]?)?(\(?\d{2}\)?[\- ]?)?[\d\- ]{7,10}$/;

type FormInputsType = {
  phone: string;
  captcha: string;
  secret_phone: string;
};

const schema = yup
  .object({
    phone: yup.string().matches(phoneRegExp, "Некорректный формат телефона").required(),
  })
  .required();

export const AuthForm = () => {
  const router = useRouter();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const confirm = useAppSelector(selectSignIn);
  const confirmStatus = useAppSelector(selectConfirmStatus);
  const captchaRef = useRef<any>(null);
  const [captcha, setCaptcha] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [isBannedModalOpen, setIsBannedModalOpen] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm<FormInputsType>({ resolver: yupResolver(schema) });

  useEffect(() => {
    if (!!captcha) {
      clearErrors("captcha");
    }
  }, [captcha]);

  useEffect(() => {
    if (hasData(confirm)) {
      setIsAuthModalOpen(true);
    }
    if (confirm.status === RemoteDataStatus.Failure) {
      // Error содержит кол-во секунд бана или просто сообщение об ошибке,
      // переводим в численный вид, если это число, то !!+confirm.error выдаст true
      if (confirm.error === "Wrong captcha code") {
        setError("captcha", { type: "custom", message: "Неверная captcha" });
        return;
      }

      if (!!+confirm.error) {
        setIsBannedModalOpen(true);
      } else {
        openErrorNotification();
      }
    }
  }, [confirm]);

  useEffect(() => {
    if (confirmStatus === RemoteDataStatus.Success && hasData(confirm)) {
      if (confirm.data.is_user_verified) {
        dispatch(setConfirmStatus(RemoteDataStatus.Pending));
        router.push("/myGoods");
      } else {
        dispatch(setConfirmStatus(RemoteDataStatus.Pending));
        router.push("/registration");
      }
    }
  }, [confirmStatus]);

  const authCloseHandler = () => {
    setIsAuthModalOpen(false);
    captchaRef.current.reset();
  };

  const submitHandler = (data: FormInputsType) => {
    if (!captcha) {
      setError("captcha", { type: "custom", message: "Неверная captcha" });
      return;
    }

    dispatch(
      signIn({
        phone: Number(data.phone.slice(1).replace(/\s/g, "")),
        secret_phone: data.secret_phone,
        google_recaptcha_response: captcha,
      }),
    );
    setPhoneNumber(data.phone);
  };

  const captchaChanger = (value) => {
    setCaptcha(value);
  };

  return (
    <S.SignWrap onSubmit={handleSubmit(submitHandler)}>
      <AuthHeader title={t("common:auth.enter")} />
      <S.InputLabel>{t("common:auth.phone")}</S.InputLabel>
      <S.PhoneInput
        type="phone"
        id="phone"
        mask={"+7 999 999 99 99"}
        placeholder={t("common:auth.phonePlaceholder")}
        {...register("phone")}
      />
      {<S.FormErrors>{errors.phone?.message}</S.FormErrors>}
      <ReCAPTCHA ref={captchaRef} sitekey="6LcU_zQpAAAAAFtckPJRWd6fKQuOtFQ9Bj5XNBDp" onChange={captchaChanger} />
      {<S.FormErrors>{errors.captcha?.message}</S.FormErrors>}
      <S.SecretPhoneInput type="phone" id="secret_phone" {...register("secret_phone")} />
      <S.GetSmsBtn
        loading={confirm.status == RemoteDataStatus.Pending}
        onClick={handleSubmit(submitHandler)}
        type="submit"
      >
        {t("common:auth.getSMS")}
      </S.GetSmsBtn>
      {isAuthModalOpen && (
        <AuthModal
          setPhoneNumber={setPhoneNumber}
          onClose={() => authCloseHandler()}
          phoneNumber={phoneNumber}
          titleText={"Введите код"}
        />
      )}
      {isBannedModalOpen && confirm.status === RemoteDataStatus.Failure && !!+confirm.error && (
        <BannedModal timer={+confirm.error} onClose={() => setIsBannedModalOpen(false)} />
      )}
    </S.SignWrap>
  );
};
