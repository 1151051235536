import styled, { css } from "styled-components";
import Image from "next/image";

export const Container = styled.div`
  ${({ theme: { breakpoints } }) => css`
    width: 100%;
    max-width: 1390px;
    margin: 0 auto;
    padding: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 80px;

    @media (max-width: ${breakpoints.smDesktopWidth}) {
      position: relative;
    }

    @media (max-width: ${breakpoints.maxTableWidth}) {
      max-width: 100%;
      padding: 0 40px;
      margin-bottom: 40px;
    }

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      width: 100%;
      padding: 0;
    }
  `}
`;

export const BackgroundImageDesktop = styled(Image)`
  ${({ theme: { breakpoints } }) => css`
    width: 800px;
    position: absolute;
    top: 0;
    right: 0;

    @media (max-width: ${breakpoints.smDesktopWidth}) {
      right: -210px;
    }

    @media (max-width: ${breakpoints.maxTableWidth}) {
      display: none;
    }
  `}
`;

export const BackgroundImageTablet = styled(Image)`
  ${({ theme: { breakpoints } }) => css`
    width: 470px;
    position: absolute;
    top: 0;
    right: 0;
    display: none;

    @media (max-width: ${breakpoints.maxTableWidth}) {
      display: block;
    }

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      display: none;
    }
  `}
`;

export const BackgroundImageMobile = styled(Image)`
  ${({ theme: { breakpoints } }) => css`
    width: 203px;
    position: absolute;
    top: 0;
    right: 0;
    display: none;

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      display: block;
    }
  `}
`;
