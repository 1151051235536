import styled, { css } from "styled-components";
import Image from "next/image";
import { Button } from "antd";

export const Wrapper = styled.div`
  ${({ theme: { breakpoints } }) => css`
    display: flex;
    flex-direction: column;
    gap: 48px;

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      border: 1px solid #e4e7ea;
      border-radius: 10.5px;
      padding: 20px;
    }
  `}
`;




export const SearchWrapper = styled.form`
  ${({ theme: { breakpoints } }) => css`
    width: 610px;
    display: flex;
    gap: 16px;
    justify-content: flex-start;

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      width: 100%;
      flex-direction: column;
    }
  `}
`;

export const InputWrap = styled.div`
  ${({ theme: { unit, breakpoints } }) => css`
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
    flex-grow: 1;
    width: 100%;

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      font-size: 16px;
      width: 334px;
    }
  `}
`;

export const ProductInputWrapper = styled.div`
  ${({ theme: { unit } }) => css`
    position: relative;
    z-index: 5;
    svg {
      max-height: 20px;
      max-width: 20px;
      min-height: 20px;
      min-width: 20px;
    }
  `}
`;

export const InputElement = styled.input`
  ${({ theme: { colors, breakpoints } }) => css`
    display: flex;
    height: 44px;
    padding: 0 16px;
    align-items: center;
    border-radius: 10.5px;
    border: 1px solid ${colors.lineGray};
    font-size: 13px;
    box-sizing: border-box;
    width: 100%;
    background-color: white;
    z-index: 2;
  `}
`;

export const BarcodeButton = styled.button`
  ${({ theme: { breakpoints } }) => css`
    position: absolute;
    right: 16px;
    top: calc(50% - 10px);
    width: max-content;
    border: none;
    background-color: inherit;
    cursor: pointer;

    svg {
      display: none;
    }

    @media (max-width: ${breakpoints.maxTableWidth}) {
      max-width: 20px;
      max-height: 20px;

      svg {
        display: initial;
        max-height: 20px;
        max-width: 20px;
        min-height: 20px;
        min-width: 20px;
      }

      span {
        display: none;
      }
    }
  `}
`;

export const BarcodeText = styled.span`
  font-size: 13px;
  text-decoration: underline;
`;

export const ProductWrap = styled.div<{ height?: string }>`
  ${({ theme: { colors }, height }) => css`
    display: flex;
    gap: 16px;
    align-items: center;
    position: relative;
    background-color: ${colors.backgroundGray};
    width: 100%;
    height: ${height ? height : "90px"};
    border-radius: 10.5px;
    padding: 8px 16px;
    box-sizing: border-box;
    z-index: 5;
    cursor: pointer;
    
    ::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 20px;
      background-color: ${colors.backgroundGray};
      top: -15px;
      left: 0;
    }
  `}
`;

export const ProductNameWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const ProductNotFound = styled.div`
  ${({ theme: { colors } }) => css`
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-size: 13px;
    color: ${colors.red};
  `}
`;

export const ImageElement = styled(Image)`
  width: 64px;
  height: 64px;
  max-width: 64px;
  border-radius: 10.5px;
  overflow: hidden;
  flex-shrink: 0;
`;

export const ArticleWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 8px;
`;

export const ProductName = styled.p`
  font-size: 14px;
  line-height: 1.3;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ProductBrandName = styled.span`
  font-size: 11px;
  line-height: 1.3;
  letter-spacing: 1.2px;
`;

export const ProductArticleName = styled.span`
  ${({ theme: { colors } }) => css`
    font-size: 11px;
    line-height: 1.3;
    letter-spacing: 1.2px;
    color: ${colors.darkGray};
  `}
`;

export const NotFoundWrapper = styled.div`
  ${({ theme: { breakpoints } }) => css`
    display: flex;
    flex-direction: column;
    max-width: 700px;
    gap: 8px;

    @media (max-width: ${breakpoints.maxTableWidth}) {
      max-width: 600px;
    }
  `}
`;

export const SearchButton = styled(Button)`
  ${({ theme: { colors, breakpoints } }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    border-radius: 10.5px;
    background-color: ${colors.black};
    color: ${colors.white};
    text-transform: uppercase;
    font-size: 13px;
    padding: 0 40px;
    width: 114px;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: ${colors.black} !important;
      color: ${colors.white} !important;
      border: none !important;
    }

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      width: 100%;
    }
  `}
`;
