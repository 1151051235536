import React, { FC, useEffect, useState } from "react";
import Modal from "@app/components/ui/modal";
import * as S from "./styled";
import { secondsToCounter } from "@app/utils/formatters";

interface IBannedModal {
  timer: number;
  onClose: () => void;
}

const BannedModal: FC<IBannedModal> = ({ timer, onClose }) => {
  const [counter, setCounter] = useState(timer);

  useEffect(() => {
    if (!counter) {
      onClose();
    }

    if (counter) {
      const interval = setInterval(() => setCounter(counter - 1), 1000);
      return () => clearInterval(interval);
    }
  }, [counter]);

  return (
    <Modal onClose={onClose}>
      <S.Wrap>
        <S.Title>Превышен лимит запросов на авторизацию</S.Title>
        <S.Content>
          <S.CounterWrapper>
            <S.CounterInfo>Повторная отправка кода возможна через:</S.CounterInfo>
            <S.Counter>{secondsToCounter(counter)}</S.Counter>
          </S.CounterWrapper>
          <S.SubscriptionContainer>
            <S.Subscription>Если у вас остались вопросы, обратитесь на почту</S.Subscription>
            <S.SubscriptionEmail href={"mailto:support@my-am.pm"}>support@my-am.pm</S.SubscriptionEmail>
          </S.SubscriptionContainer>
        </S.Content>
      </S.Wrap>
    </Modal>
  );
};

export default BannedModal;
