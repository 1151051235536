import styled, { css } from "styled-components";

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Title = styled.h2`
  ${({ theme: { breakpoints } }) => css`
    font-size: 40px;
    font-weight: 400;
    line-height: 1.3;
    text-align: center;

    @media (max-width: ${breakpoints.maxTableWidth}) {
      font-size: 32px;
    }

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      font-size: 18px;
    }
  `}
`;

export const BrandsWrap = styled.div`
  ${({ theme: { breakpoints } }) => css`
    display: flex;
    justify-content: center;
    gap: 16px;

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      display: none;
    }
  `}
`;
