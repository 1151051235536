import styled, {css} from "styled-components";

export const Wrap = styled.div`
  ${({ theme: { breakpoints } }) => css`
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 320px;

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      max-width: 280px;
      width: 100%;
      gap: 24px;
    }
  `}
`;

export const Title = styled.h3`
  ${({ theme: { breakpoints } }) => css`
    font-size: 22px;
    font-weight: 400;
    max-width: 300px;

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      font-size: 18px;
    }
  `}
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 24px;
`;

export const CounterWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
`;

export const Subscription = styled.span`
  ${({ theme: { colors } }) => css`
    font-size: 12px;
    color: ${colors.darkGray};
    text-align: center;
  `}
`;

export const SubscriptionContainer = styled.span`
  display: flex;
  flex-direction: column;
  line-height: 1.5;
`;

export const SubscriptionEmail = styled.a`
  ${({ theme: { colors } }) => css`
    font-size: 12px;
    color: ${colors.darkGray};
    text-align: center;
    text-decoration: underline;
    
    &:hover {
      color: ${colors.black};
      text-decoration: underline;
    }
  `}
`;

export const CounterInfo = styled.span`
  width: 150px;
  font-size: 14px;
`;

export const Counter = styled.span`
  ${({ theme: { colors } }) => css`
    font-size: 22px;
  `}
`;