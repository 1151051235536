import { type TabsProps } from "antd";
import * as S from "./styled";
import { FC } from "react";

type Props = {
  items: TabsProps["items"];
  initialTab: string;
};

export const Tabs: FC<Props> = ({ items, initialTab }) => {
  return <S.TabsGroup defaultActiveKey={initialTab} items={items} />;
};
