import { FC } from "react";
import * as S from "./styled";
import Logo from "../../../../icons/icon-ampm.svg";
import LogoDamixa from "../../../../icons/icon-damixa.svg";
import LogoDorff from "../../../../icons/icon-dorff.svg";

type AuthHeaderType = {
  title: string;
};

export const AuthHeader: FC<AuthHeaderType> = ({ title }) => {
  return (
    <S.Header>
      <S.Title>{title}</S.Title>
      <S.BrandsWrap>
        <Logo />
        <LogoDamixa />
        <LogoDorff />
      </S.BrandsWrap>
    </S.Header>
  );
};
