import React, { FC, useEffect, useState } from "react";
import Modal from "@app/components/ui/modal";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import * as S from "./styled";
import { useAppDispatch, useAppSelector } from "@app/store";
import { confirmUser, resetConfirmation, resetConfirmationStatus, setConfirmStatus } from "@app/store/core/slice";
import { selectConfirmStatus, selectResetConfirmStatus, selectSignIn } from "@app/store/core/selectors";
import { hasData, initialized, isPending, RemoteDataStatus } from "../../../../../libs/remote";
import ReCAPTCHA from "react-google-recaptcha";

type FormInputsType = {
  code: string;
  captcha: string;
};

const schema = yup
  .object({
    phone: yup.string().required().max(4),
  })
  .required();

type Props = {
  onClose: () => void;
  setPhoneNumber: (number: string) => void;
  phoneNumber: string;
  titleText: string;
};

const AuthModal: FC<Props> = ({ onClose, phoneNumber, titleText, setPhoneNumber }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [isNewCodeAvailable, setIsNewCodeAvailable] = useState(false);
  const [timer, setTimer] = useState(59);
  const dispatch = useAppDispatch();
  const confirm = useAppSelector(selectSignIn);
  const confirmStatus = useAppSelector(selectConfirmStatus);
  const resetConfirmStatus = useAppSelector(selectResetConfirmStatus);
  const [code, setCode] = useState("");
  const [captcha, setCaptcha] = useState("");

  const {
    handleSubmit,
    setError,
    formState: { errors },
    clearErrors,
  } = useForm<FormInputsType>({ resolver: yupResolver(schema) });

  useEffect(() => {
    return () => {
      dispatch(resetConfirmationStatus(initialized()));
    };
  }, []);

  useEffect(() => {
    if (!!captcha) {
      clearErrors("captcha");
    }
  }, [captcha]);

  useEffect(() => {
    if (confirmStatus === RemoteDataStatus.Failure) {
      setErrorMessage("Неверный код. Попробуйте ещё раз");
    }
  }, [confirmStatus]);

  useEffect(() => {
    if (!!timer) {
      const interval = setInterval(() => setTimer(timer - 1), 1000);
      setIsNewCodeAvailable(false);

      return () => clearInterval(interval);
    } else {
      setIsNewCodeAvailable(true);
    }
  }, [timer]);

  useEffect(
    () => () => {
      setErrorMessage(null);
      dispatch(setConfirmStatus(RemoteDataStatus.Initialized));
    },
    [],
  );

  useEffect(() => {
    if (resetConfirmStatus.status === RemoteDataStatus.Failure && resetConfirmStatus.error === "Wrong captcha code") {
      setError("captcha", { type: "custom", message: "Неверная captcha" });
      return;
    }

    if (resetConfirmStatus.status === RemoteDataStatus.Failure) {
      setPhoneNumber("");
      onClose();
      dispatch(resetConfirmationStatus(initialized()));
    }
  }, [resetConfirmStatus]);

  const onSubmit = (value?: string) => {
    if (confirm.status === RemoteDataStatus.Success) {
      dispatch(confirmUser({ confirmation_id: confirm.data.confirmation_id, code: value ?? code }));
    }
  };

  const captchaChanger = (value) => {
    setCaptcha(value);
  };

  const newCodeSubmit = () => {
    if (!captcha) {
      setError("captcha", { type: "custom", message: "Неверная captcha" });
      return;
    }

    if (confirm.status === RemoteDataStatus.Success) {
      dispatch(
        resetConfirmation({ confirmation_id: confirm.data.confirmation_id, google_recaptcha_response: captcha }),
      );
      setCaptcha("");
      setTimer(59);
    }
  };

  const props = {
    inputStyle: {
      MozAppearance: "textfield",
      borderRadius: "10.5px",
      fontSize: "16px",
      height: "44px",
      color: "black",
      width: "44px",
      textAlign: "center",
      border: "1px solid #E4E7EA",
    },
  };

  return (
    <Modal onClose={onClose}>
      <S.Wrap>
        <S.Title>{titleText}</S.Title>
        <S.ContentWrap>
          <S.Content>{`Мы отправили код подтверждения на номер ${phoneNumber}`}</S.Content>
          <S.ChangePhoneButton onClick={onClose}>Изменить</S.ChangePhoneButton>
        </S.ContentWrap>
        <S.Form onSubmit={handleSubmit(() => onSubmit())}>
          <S.InputLabel htmlFor="code">
            <S.InputTitle>Код подтверждения:</S.InputTitle>
            <S.ConfirmInput
              type={"number"}
              name={"code"}
              inputMode={"numeric"}
              value={code}
              onChange={(e: string) => {
                setCode(e);
                if (e.length === 4) {
                  onSubmit(e);
                }
              }}
              {...props}
            />
          </S.InputLabel>
          <S.FormErrors>{errorMessage}</S.FormErrors>
          {!isNewCodeAvailable && (
            <>
              <S.GetNewCodeTimer>{`Получить новый код можно через 00:${
                timer < 10 ? 0 : ""
              }${timer}`}</S.GetNewCodeTimer>
              {hasData(resetConfirmStatus) && (
                <S.GetNewCodeTimer>{`Попыток осталось: ${resetConfirmStatus.data.retries}`}</S.GetNewCodeTimer>
              )}
            </>
          )}
          {!!+code && (
            <S.SubmitCode type="submit" onClick={onSubmit} loading={confirmStatus === RemoteDataStatus.Pending}>
              Отправить
            </S.SubmitCode>
          )}
          {isNewCodeAvailable && (
            <>
              <ReCAPTCHA sitekey="6LcU_zQpAAAAAFtckPJRWd6fKQuOtFQ9Bj5XNBDp" onChange={captchaChanger} />
              {<S.FormErrors>{errors.captcha?.message}</S.FormErrors>}
              <S.GetNewCode onClick={newCodeSubmit} loading={isPending(resetConfirmStatus)}>
                Получить новый код
              </S.GetNewCode>
            </>
          )}
        </S.Form>
      </S.Wrap>
    </Modal>
  );
};

export default AuthModal;
