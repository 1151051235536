import React, { FC } from "react";
import BackgroundImageDesktop from "@app/images/main-page-desktop.png";
import BackgroundImageTablet from "@app/images/main-page-tablet.png";
import * as S from "./styled";
import { Tabs } from "./components/tabs";
import { ArticleSearch } from "./components/articleSearch";
import { AuthForm } from "./components/authForm";

const TAB_ITEMS = [
  {
    key: "1",
    label: "Личный кабинет",
    children: <AuthForm />,
  },
  {
    key: "2",
    label: "Условия гарантии",
    children: <ArticleSearch />,
  },
];

const AuthPage: FC = () => {
  return (
    <S.Container>
      <S.BackgroundImageDesktop src={BackgroundImageDesktop} alt={"Фоновое изображение"} />
      <S.BackgroundImageTablet src={BackgroundImageTablet} alt={"Фоновое изображение"} />
      <S.BackgroundImageMobile src={BackgroundImageTablet} alt={"Фоновое изображение"} />
      <Tabs items={TAB_ITEMS} initialTab="2" />
    </S.Container>
  );
};

export default AuthPage;
