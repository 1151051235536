import dayjs from "dayjs";

export const getFormatedDate = (date: string) => dayjs(date).locale("ru").format("D MMMM YYYY");

export const secondsToCounter = (seconds: number) => {
    let countHours = Math.floor(seconds / 3600).toString();
    let countMin = Math.floor((seconds - (+countHours * 3600)) / 60).toString();
    let countSecond = (seconds - +countMin * 60 - +countHours * 3600).toString();

    if (+countHours < 10) countHours = `0${countHours}`;
    if (+countMin < 10) countMin = `0${countMin}`;
    if (+countSecond < 10) countSecond = `0${countSecond}`;

    return `${countHours}:${countMin}:${countSecond}`;
}
