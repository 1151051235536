import * as S from "./styled";

import { useTranslation } from "next-i18next";
import IconBarcode from "../../../../icons/icon-code.svg";
import DefaultImage from "../../../../images/default-product-image.png";
import WrongWarrantyDesc from "@app/components/entities/wrongWarrantyDesc";
import { hasData, initialized, RemoteDataStatus } from "libs/remote";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "@app/store";
import { fetchSearchProducts, setSearchProduct } from "@app/store/myGoods/slice";
import BarcodeModal from "@app/views/registrationPage/components/barcodeFileReader";
import { selectSearchProduct } from "@app/store/myGoods/selectors";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Modal from "@app/components/ui/modal";
import { ProductWarrantyGridDTO } from "@app/api/generated-api";
import { AuthHeader } from "../authHeader";
import GuaranteeRules from "@app/views/detailGoodsPage/components/guaranteeRules";

type SchemaType = {
  search: string;
};

export const ArticleSearch = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [openScanner, setOpenScanner] = useState(false);
  const searchProducts = useAppSelector(selectSearchProduct);
  const [isModal, setIsModal] = useState(false);
  const [product, setProduct] = useState<ProductWarrantyGridDTO>();

  const Schema = yup.object({
    search: yup
      .string()
      .trim()
      .required("")
      .matches(/^[a-zA-Z0-9-]+$/, "Некорректный артикул")
      .min(4, "Введите артикул от четырех символов"),
  });

  const { handleSubmit, control } = useForm<SchemaType>({
    resolver: yupResolver(Schema),
    mode: "onChange",
  });

  const toggleModal = () => {
    setIsModal((prev) => !prev);
  };

  const handleProductClick = (key: string) => {
    if (hasData(searchProducts)) {
      const selectedProduct = searchProducts.data.data.warranty_product_list.find((item) => item.guid === key);

      setProduct(selectedProduct);
      toggleModal();
    }
  };

  const onSubmit: SubmitHandler<SchemaType> = ({ search: q }) => {
    dispatch(fetchSearchProducts({ q }));
  };

  const openScannerHandler = () => {
    setOpenScanner(true);
  };

  return (
    <S.Wrapper>
      <AuthHeader title="Условия гарантии" />
      <S.SearchWrapper onSubmit={(e) => e.preventDefault()}>
        <Controller
          control={control}
          name="search"
          render={({ field, formState: { errors } }) => (
            <S.InputWrap>
              <S.ProductInputWrapper>
                <S.InputElement
                  placeholder="Что будем искать?"
                  type="text"
                  {...field}
                  onChange={(e) => {
                    const value: string = e.target.value;

                    field.onChange(value);

                    if (searchProducts.status !== RemoteDataStatus.Initialized)
                      dispatch(setSearchProduct(initialized()));
                  }}
                />
                <S.BarcodeButton onClick={openScannerHandler} type="button">
                  <IconBarcode />
                  <S.BarcodeText>{t("common:registration.uploadArticle")}</S.BarcodeText>
                </S.BarcodeButton>
              </S.ProductInputWrapper>
              {errors?.search?.message && <S.ProductNotFound>{errors.search.message}</S.ProductNotFound>}
              {hasData(searchProducts) &&
                (searchProducts.data.data.content_product_list.length > 0 ? (
                  searchProducts.data.data.content_product_list.map((product) => (
                    <S.ProductWrap onClick={() => handleProductClick(product.guid)} key={product.guid}>
                      <S.ImageElement
                        src={product?.images[0]?.desktop?.jpg_double ?? DefaultImage.src}
                        alt="фото товара"
                        width={64}
                        height={64}
                      />
                      <S.ProductNameWrap>
                        <S.ArticleWrapper>
                          <S.ProductBrandName>{product.brand?.name ?? product._1c_brand}</S.ProductBrandName>
                          <S.ProductArticleName>{product.article}</S.ProductArticleName>
                        </S.ArticleWrapper>
                        <S.ProductName>{product.name}</S.ProductName>
                      </S.ProductNameWrap>
                    </S.ProductWrap>
                  ))
                ) : (
                  <S.ProductWrap height={"max-content"}>
                    <S.NotFoundWrapper>
                      <S.ProductNotFound>{t("common:registration.productNotFound")}</S.ProductNotFound>
                      <WrongWarrantyDesc wrong="product" />
                    </S.NotFoundWrapper>
                  </S.ProductWrap>
                ))}
              {openScanner && !!window && (
                <BarcodeModal onClose={() => setOpenScanner(false)} setCode={(code) => field.onChange(code)} />
              )}
            </S.InputWrap>
          )}
        />

        <S.SearchButton onClick={handleSubmit(onSubmit)}>Найти</S.SearchButton>
      </S.SearchWrapper>

      {isModal && (
        <Modal onClose={toggleModal}>
          <GuaranteeRules product={product} />
        </Modal>
      )}
    </S.Wrapper>
  );
};
