import styled, { css } from "styled-components";
import InputMask from "react-input-mask";
import { Button } from "antd";

export const SignWrap = styled.form`
  ${({ theme: { breakpoints, colors } }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      border: 1px solid ${colors.lineGray};
      border-radius: 10.5px;
      padding: 20px;
    }
  `}
`;

export const SignTitle = styled.h2`
  ${({ theme: { breakpoints } }) => css`
    font-size: 40px;
    font-weight: 400;
    line-height: 1.3;

    @media (max-width: ${breakpoints.maxTableWidth}) {
      font-size: 32px;
    }

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      font-size: 18px;
    }
  `}
`;

export const BrandsWrap = styled.div`
  ${({ theme: { breakpoints } }) => css`
    display: flex;
    justify-content: center;
    gap: 16px;

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      display: none;
    }
  `}
`;

export const InputLabel = styled.p`
  ${({ theme: { breakpoints } }) => css`
    font-size: 13px;
    display: none;
    margin-top: 8px;

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      display: flex;
    }
  `}
`;

export const PhoneInput = styled(InputMask)`
  ${({ theme: { colors, breakpoints } }) => css`
    border-radius: 10.5px;
    border: 1px solid ${colors.lineGray};
    display: flex;
    align-items: center;
    height: 44px;
    width: 283px;
    font-size: 13px;
    padding: 0 16px;
    align-self: center;
    margin-top: 32px;

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      width: 334px;
      margin-top: 0;
      font-size: 16px;
    }
  `}
`;

export const SecretPhoneInput = styled(InputMask)`
  ${({ theme: { colors, breakpoints } }) => css`
    border-radius: 0px;
    border: 0px solid ${colors.lineGray};
    display: flex;
    align-items: center;
    height: 0px;
    width: 0px;
    font-size: 0px;
    padding: 0px;
    align-self: center;
    margin-top: 0px;

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      width: 0px;
      margin-top: 0;
      font-size: 0;
    }
  `}
`;

export const FormErrors = styled.p`
  ${({ theme: { colors } }) => css`
    font-size: 11px;
    color: ${colors.red};
  `}
`;

export const GetSmsBtn = styled(Button)`
  ${({ theme: { colors, breakpoints } }) => css`
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    height: 44px;
    border-radius: 10.5px;
    background-color: ${colors.black};
    color: ${colors.white};
    text-transform: uppercase;
    font-size: 13px;
    padding: 0 40px;
    width: 283px;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: ${colors.black} !important;
      color: ${colors.white} !important;
      border: none !important;
    }

    @media (max-width: ${breakpoints.maxMobileWidth}) {
      width: 334px;
    }
  `}
`;
